<template>
  <div class="row q-ma-lg">
    <certificate class="full-width" />
  </div>
</template>

<script>
import Certificate from "@/modules/main/pages/course/certificate";

export default {
  name: "public.certificate",

  components: { Certificate },
};
</script>
